/* Слайс, хранящий и авторизацию, и регистрацию, и инфу о залогиненом текущем юзере */

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ActivateAccount,
  GetConstants,
  ResetPassword,
  SignUp,
  GetPermissions,
  LogIn,
  LogOut,
  GetProfiles,
  ChangeProfile,
  GetTenantProfileData,
  GetProfileExtraData,
  ChangeEmailFinishing,
  AddProfileFirstStep,
  AddProfileSecondStep,
} from 'app/api/auth';
import { showWarning } from 'app/store/root-slice';
import { GW_SERVICES_PREFIXES } from '../../constants/gateway';
import { IConstants, requestStatusType } from 'app/types';
import { getBgImage, getFaviconImage } from 'app/utils/random-images';
import {
  IProfileExtraData,
  IAuthStatuses,
  ICredentials,
  ICRUDPermissions,
  IRegisterDetails,
  ITenantProfile,
  ITenantProfileShort,
} from './types';

const { AUTH } = GW_SERVICES_PREFIXES;

export interface InitialState {
  user: ITenantProfile;
  /*
    permissions - это старые tabs из auth/current с правами,
    но в другом виде (необходимо переделывать)
  */
  profiles: ITenantProfileShort[];
  profileExtraData: IProfileExtraData;
  permissions: ICRUDPermissions;
  constants: IConstants;
  onLoad: boolean; // Процесс загрузки инфы о юзере(влияет на то, авторизован он или нет, в том числе)
  isPerson: boolean;
  isAuth: boolean;
  bgImage: any;
  favImage: any;
  login: {
    isError: boolean;
  };
  register: {
    isError: boolean | string;
  };
  resetPassword: {
    isError: boolean;
  };
  activate: {
    isError: boolean | string;
  };
  addProfileFirstStep: {
    error: boolean | string;
  };
  addProfileSecondStep: {
    isError: boolean | string;
  };
  visit_counter: number;
  statuses: IAuthStatuses;
}

export const initialState: InitialState = {
  user: {} as ITenantProfile,
  permissions: {} as ICRUDPermissions,
  profiles: [],
  profileExtraData: {} as IProfileExtraData,
  constants: {} as IConstants,
  onLoad: false,
  isPerson: false,
  isAuth: false,
  bgImage: '',
  favImage: '',
  login: {
    isError: false,
  },
  register: {
    isError: false,
  },
  resetPassword: {
    isError: false,
  },
  activate: {
    isError: false,
  },
  addProfileFirstStep: {
    error: false,
  },
  addProfileSecondStep: {
    isError: false,
  },
  visit_counter: 0,
  statuses: [
    'permissions',
    'profiles',
    'profileExtraData',
    'tenantProfileData',
    'changeProfile',
  ].reduce(
    (res, key) => ({ ...res, [key]: 'not executed' }),
    {} as IAuthStatuses
  ),
};

export const getDynamicImages = createAsyncThunk(
  'auth/getDynamicImages',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const bgImage = await getBgImage();
      const favImage = await getFaviconImage();
      return {
        bgImage,
        favImage,
      };
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getTenantProfileData = createAsyncThunk<any>(
  'auth/getTenantProfileData',
  async (_, { rejectWithValue }) => {
    try {
      return await GetTenantProfileData();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getConstants = createAsyncThunk(
  'auth/getConstants',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await GetConstants();
      return response.results;
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const logIn = createAsyncThunk<any, ICredentials, {}>(
  'auth/logIn',
  async (credentials, { rejectWithValue, dispatch }) => {
    try {
      return await LogIn(credentials);
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

// в ответе на эндпоинт auth/verify/ есть вкладка с permissions
export const getPermissions = createAsyncThunk<any>(
  'auth/getPermissions',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await GetPermissions();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const logOut = createAsyncThunk<any>(
  'auth/logOut',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      await LogOut();
      // Вместо очистки всех данных в store
      document.location.assign(document.location.origin + '/#/auth/login/');
      document.location.reload();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const register = createAsyncThunk<
  any, // Возвращаемое значение
  IRegisterDetails, // credentials
  any // dispatch и тп
>('auth/register', async (details, { rejectWithValue, dispatch }) => {
  try {
    return await SignUp(details);
  } catch (e) {
    const response = await e.response.json();
    return rejectWithValue(response);
  }
});

export const addProfileFirstStep = createAsyncThunk<
  any,
  IRegisterDetails,
  any
>('auth/addProfileFirstStep', async (details, { rejectWithValue, dispatch }) => {
  try {
    return await AddProfileFirstStep(details);
  } catch (e) {
    const response = await e.response.json();
    return rejectWithValue(response);
  }
});

export const getProfiles = createAsyncThunk<any>(
  'auth/getProfiles',
  async (_, { rejectWithValue }) => {
    try {
      return await GetProfiles();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const getProfileExtraData = createAsyncThunk<any>(
  'auth/getProfileExtraData',
  async (_, { rejectWithValue }) => {
    try {
      return await GetProfileExtraData();
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

// смена профиля в сервисе авторизации (с обновлением токенов в cookies)
export const changeProfile = createAsyncThunk<any, string, any>(
  'auth/changeProfile',
  async (profile, { dispatch, rejectWithValue }) => {
    try {
      return await ChangeProfile({ profile });
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const resetPassword = createAsyncThunk<
  any,
  string,
  any
>('auth/resetPassword', async (username, { rejectWithValue, dispatch }) => {
  try {
    return await ResetPassword(username);
  } catch (e) {
    return rejectWithValue(e.message);
  }
});

export const activateAccount = createAsyncThunk<
  any,
  {
    areaNumber: string;
    profileId: string;
    userId: string;
  },
  any
>('auth/activateAccount', async (data, { rejectWithValue, dispatch }) => {
  try {
    return await ActivateAccount(data);
  } catch (e) {
    if (e.response.status === 500) {
      dispatch(showWarning());
      return rejectWithValue(e.message);
    } else {
      const response = await e.response.json();
      return rejectWithValue(response);
    }
  }
});

export const addProfileSecondStep = createAsyncThunk<
  any,
  {
    areaNumber: string;
    userId: string;
    profileId: string;
    activationCode: string;
  },
  any
>('auth/addProfileSecondStep', async (data, { rejectWithValue, dispatch }) => {
  try {
    return await AddProfileSecondStep(data);
  } catch (e) {
    if (e.response.status === 500) {
      dispatch(showWarning());
      return rejectWithValue(e.message);
    } else {
      const response = await e.response.json();
      return rejectWithValue(response);
    }
  }
});


export const changeEmail = createAsyncThunk<
  any,
  {
    userId: string;
    code: string;
  },
  any
>(
  'auth/changeEmail',
  async ({ userId, code }, { dispatch, rejectWithValue }) => {
    try {
      await fetch(
        `/${AUTH}/api/v1/account/change_email/${userId}/${code}`,
        {
          credentials: 'include',
          redirect: 'follow',
          method: 'GET',
        }
      ).then((r) => {
        if (r.redirected) {
          window.location.href = r.url;
        }
      });
    } catch (e) {
      return rejectWithValue(e.message);
    }
  }
);

export const changeEmailFinishing = createAsyncThunk<
  any,
  { userId: string; areaNumber: string, profileId: string },
  any
>(
  'profile/changeAccountEmail',
  async ({ userId, areaNumber, profileId}, { rejectWithValue, dispatch }) => {
    try {
      return await ChangeEmailFinishing(userId, areaNumber, profileId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.register.isError = false;
      state.login.isError = false;
      state.resetPassword.isError = false;
      state.activate.isError = false;
    },
    clearLoginError: (state) => {
      state.register.isError = false;
      state.addProfileFirstStep.error = false;
    },
    clearStatuses: (state) => {
      state.statuses = initialState.statuses;
    },
    visitIncrement: (state, action: PayloadAction<number>) => {
      state.visit_counter = action.payload;
    },
    setTenantProfileDataStatus: (
      state,
      action: PayloadAction<requestStatusType>
    ) => {
      state.statuses.tenantProfileData = action.payload;
    },
    setProfileNumberWithPermissionsStatus: (
      state,
      action: PayloadAction<requestStatusType>
    ) => {
      state.statuses.profileExtraData = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Получение информации о пользователе
    builder.addCase(getDynamicImages.fulfilled, (state, action) => {
      state.bgImage = action.payload.bgImage;
      state.favImage = action.payload.favImage;
    });

    // Получение всех основных констант ЛКЖ
    builder.addCase(getConstants.fulfilled, (state, action) => {
      state.constants = action.payload;
    });

    builder.addCase(getPermissions.pending, (state) => {
      state.statuses.permissions = 'pending';
    });

    builder.addCase(getPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload;
      state.statuses.permissions = 'success';
    });

    builder.addCase(getPermissions.rejected, (state) => {
      state.statuses.permissions = 'error';
    });

    builder.addCase(getProfiles.pending, (state) => {
      state.statuses.profiles = 'pending';
    });

    builder.addCase(getProfiles.fulfilled, (state, action) => {
      state.profiles = action.payload;
      state.statuses.profiles = 'success';
    });

    builder.addCase(getProfiles.rejected, (state) => {
      state.statuses.profiles = 'error';
    });

    builder.addCase(getProfileExtraData.pending, (state) => {
      state.statuses.profileExtraData = 'pending';
    });

    builder.addCase(
      getProfileExtraData.fulfilled,
      (state, action) => {
        state.profileExtraData = action.payload;
        state.statuses.profileExtraData = 'success';
      }
    );

    builder.addCase(getProfileExtraData.rejected, (state) => {
      state.statuses.profileExtraData = 'error';
    });

    builder.addCase(changeProfile.pending, (state) => {
      state.statuses.changeProfile = 'pending';
    });

    builder.addCase(getTenantProfileData.fulfilled, (state, action) => {
      state.user = action.payload;
      state.statuses.tenantProfileData = 'success';
    });

    builder.addCase(getTenantProfileData.rejected, (state) => {
      state.statuses.tenantProfileData = 'error';
    });

    builder.addCase(getTenantProfileData.pending, (state) => {
      state.statuses.tenantProfileData = 'pending';
    });

    builder.addCase(changeProfile.fulfilled, (state) => {
      /*
        Сервис аутентификации/авторизации вернет Set-Cookie с токенами,
        в которых будет запрашиваемый профиль.
        В response.data нет ничего полезного.
      */
      state.statuses.changeProfile = 'success';
    });

    builder.addCase(changeProfile.rejected, (state) => {
      state.statuses.changeProfile = 'error';
    });

    // Авторизация
    builder.addCase(logIn.pending, (state) => {
      state.login.isError = false;
    });
    builder.addCase(logIn.fulfilled, (state, { payload }) => {
      state.login.isError = false;
      // state.visit_counter = payload.visit_counter;
    });
    builder.addCase(logIn.rejected, (state) => {
      state.login.isError = true;
    });

    // Регистрация
    builder.addCase(register.pending, (state) => {
      state.register.isError = false;
    });
    builder.addCase(register.fulfilled, (state) => {
      state.register.isError = false;
    });
    builder.addCase(register.rejected, (state, action) => {
      let error: any = action.payload;
      state.register.isError = error.message || error as string;
    });

    // Активация
    builder.addCase(activateAccount.pending, (state) => {
      state.activate.isError = false;
    });
    builder.addCase(activateAccount.fulfilled, (state) => {
      state.activate.isError = false;
    });
    builder.addCase(activateAccount.rejected, (state) => {
        state.activate.isError = true;
    });

    // Добавление ЛС 1-й этап
    builder.addCase(addProfileFirstStep.pending, (state) => {
      state.addProfileFirstStep.error = false;
    });
    builder.addCase(addProfileFirstStep.fulfilled, (state) => {
      state.addProfileFirstStep.error = false;
    });
    builder.addCase(addProfileFirstStep.rejected, (state, action) => {
      state.addProfileFirstStep.error = action.payload as string;
    });

    // Добавление ЛС 2-й этап
    builder.addCase(addProfileSecondStep.pending, (state) => {
      state.addProfileSecondStep.isError = false;
    });
    builder.addCase(addProfileSecondStep.fulfilled, (state) => {
      state.addProfileSecondStep.isError = false;
    });
    builder.addCase(addProfileSecondStep.rejected, (state) => {
        state.addProfileSecondStep.isError = true;
    });

    // Сброс пароля
    builder.addCase(resetPassword.pending, (state) => {
      state.resetPassword.isError = false;
    });
    builder.addCase(resetPassword.fulfilled, (state) => {
      state.resetPassword.isError = false;
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.resetPassword.isError = true;
    });
  },
});

export const {
  visitIncrement,
  setTenantProfileDataStatus,
  setProfileNumberWithPermissionsStatus,
  clearStatuses,
} = authSlice.actions;
export default authSlice.reducer;
