import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { GetPayments, DownloadReceipt } from '../../api/payments';
import { RootState } from '../../store/store';
import { formatDateToISO } from '../../utils/getters';
import { getTenantProfileData } from '../auth/auth-slice';
import { IPayment, ITransaction, ICommercial, IAuthStatuses } from './types';

export interface InitialState {
  payments: (IPayment | ITransaction | ICommercial)[];
  sectors: string[];
  filters: {
    sectors: string[];
    date_from: Date;
    date_till: Date;
  };
  statuses: IAuthStatuses;
}

export const initialState: InitialState = {
  payments: [],
  sectors: [],
  filters: {
    sectors: [],
    date_from: moment().subtract(1, 'year').startOf('M').toDate(),
    date_till: moment().endOf('M').toDate(),
  },
  statuses: {
    payments: 'not executed',
  },
};

export const getPayments = createAsyncThunk(
  'payments/getPayments',
  async (_, { rejectWithValue, getState }) => {
    const {
      payments: { sectors, filters },
    } = <RootState>getState();
    try {
      return await GetPayments({
        sectors,
        date_from: formatDateToISO(filters.date_from),
        date_till: formatDateToISO(
          moment(filters.date_till).endOf('M').startOf('d')
        ),
      });
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const downloadReceipt = createAsyncThunk(
  'payments/downloadReceipt',
  async (docId: string, { rejectWithValue }) => {
    try {
      return await DownloadReceipt(docId);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setDateRange: (
      state,
      action: PayloadAction<{ date_from: Date; date_till: Date }>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    setFilterSectors: (state, action: PayloadAction<string[]>) => {
      state.filters.sectors = action.payload;
    },
    // request_commercial не внести в поле sectors, которое приходит из auth/current, пришлось в этом месте захардкодить, на самой странице в зависимости от того, есть ли в истории платежей коммерческая заявка, диспатчится экшен и отрисовывается таб для фильтрации.
    setCommercialRequests: (state) => {
      state.sectors = [...state.sectors, 'request_commercial'];
      state.filters.sectors = [...state.filters.sectors, 'request_commercial'];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPayments.pending, (state) => {
      state.statuses.payments = 'pending';
    });
    builder.addCase(getPayments.rejected, (state) => {
      state.statuses.payments = 'error';
    });
    builder.addCase(
      getPayments.fulfilled,
      (
        state,
        action: PayloadAction<{
          results: (IPayment | ITransaction | ICommercial)[];
        }>
      ) => {
        state.payments = action.payload.results;
        state.statuses.payments = 'success';
      }
    );
    builder.addCase(getTenantProfileData.fulfilled, (state, action) => {
      state.sectors = action.payload.sectors;
      state.filters.sectors = action.payload.sectors;
      // если изменен профиль через интерфейс
      state.statuses.payments = 'not executed';
    });
  },
});

export const { setDateRange, setFilterSectors, setCommercialRequests } =
  paymentsSlice.actions;

export default paymentsSlice.reducer;
