import { API } from './api';
import {
  IBasketItem,
  interfaceParamsPayment,
  IOrderPayload,
} from '../pages/requests/types';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const GetTemplateRequests = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/template_request/`);

// получение всех корзин в каталоге (у каждой категории своя корзина)
export const GetServerBasket = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/template_request/receiving_services/`);

export const CreateOrder = (order: IOrderPayload) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/template_request/make_request/`, order);

export const GetRequisites = (params: interfaceParamsPayment) =>
  API.get(`/${INTERNAL}/api/v4/acquiring/tenant_request_info/`, params);

export const PostToBasket = ({
  category,
  services,
}: {
  category: string;
  services: IBasketItem[];
}) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/template_request/service_amount/`, {
    category,
    services,
  });
