import { API } from './api';
import { IConfig } from '../pages/notifications/types';

/* Получение текущих настроек оповещений */
export const getCurrentConfig = (params?: {
  tenant_id: string;
}): Promise<IConfig> => API.get('/notifications/refusal/config/', params);

/* Изменение текущих настроек оповещений */
export const changeConfig = (data: {
  tenant_id?: string;
  email: boolean;
  mobile_app: boolean;
  telegram: boolean;
  vk: boolean;
}) => API.post('/notifications/refusal/channels/', data);

/* Перенос адреса пользователя в архив и отказ от оповещений */
export const sendWrongAddress = (data?: { tenant_id: string }) =>
  API.post('/notifications/refusal/wrong_address/', data);

/* Получение полной ссылки на настройку оповещений для пользователя, перешедшего извне */
export const getQueryUrl = (id: string) => API.get(`/notifications/unsubscribe/${id}/`);
