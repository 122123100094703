import { IRegisterDetails } from '../pages/auth/types';
import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';
import { getCookie } from '../utils/cookies';

const { INTERNAL, AUTH } = GW_SERVICES_PREFIXES;

export const GetConstants = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/constants/`);

export const LogIn = (credentials: any) =>
  API.post(`/${AUTH}/api/v1/auth/login/`, credentials);

/*
  GetPermissions() на данный момент не используется.
  В ответе будет объект, в котором ключи - слаги, а значения - CRUD строка.
  Для получения прав используется GetProfileExtraData().
*/
export const GetPermissions = (extraHeaders?: { [key: string]: string }) =>
  API.get(`/${AUTH}/api/v1/permissions/`, extraHeaders);

export const LogOut = () => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/auth/logout/`,
    undefined,
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const SignUp = (data: IRegisterDetails) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/register/tenant/`,
    {
      last_name: data.surname,
      number: data.accountNumber,
      username: data.email,
    },
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const AddProfileFirstStep = (data: IRegisterDetails) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/add_profile/tenant/`,
    {
      last_name: data.surname,
      number: data.accountNumber,
      username: data.email,
    },
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const ResetPassword = (username: string) =>
  API.post(`/${AUTH}/api/v1/account/reset_password/`, {
    username,
  });

export const ActivateAccount = ({
  areaNumber,
  profileId,
  userId,
}: {
  areaNumber: string;
  profileId: string;
  userId: string;
}) =>
  API.post(`/${AUTH}/api/v1/account/activate/second_step/tenant/`, {
    user_id: userId,
    profile_id: profileId,
    area_number: areaNumber,
  });

export const AddProfileSecondStep = ({
  areaNumber,
  userId,
  profileId,
  activationCode,
}: {
  areaNumber: string;
  profileId: string;
  userId: string;
  activationCode: string;
}) =>
  API.post(`/${AUTH}/api/v1/account/add_profile/second_step/`, {
    user_id: userId,
    profile_id: profileId,
    area_number: areaNumber,
    activation_code: activationCode,
  });

// Список лицевых счетов (профилей) пользователя
export const GetProfiles = () =>
  API.get(`/${AUTH}/api/v1/auth/get_profile/`);

export const GetProfileExtraData = () => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/auth/verify/`,
    undefined,
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const ChangeProfile = (body: { profile: string }) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/auth/change_profile/`,
    body,
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const GetTenantProfileData = () =>
  API.get(`/${INTERNAL}/api/v4/auth/tenant_profile/`);

export const ChangeEmailFinishing = (
  userId: string,
  areaNumber: string,
  profileId: string
) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/change_email_finishing/`,
    {
      user_id: userId,
      area_number: areaNumber,
      profile_id: profileId,
    },
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};
