import React, { useEffect, useState } from 'react';
import 'react-awesome-slider/dist/styles.css';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import {
  AuthLoader,
  Loader,
  Warning,
  ServerError,
  TimeWarning,
} from './components';
import { getAuthStateSelector } from './pages/auth/selectors';
import { GlobalStyle } from './styles/global.styled';
import { mainTheme } from './styles/main-theme';
import { lazyLoad } from './utils/loadable';
import { useMediaQuery } from 'react-responsive';
import { MFMedia } from './styles/media-breakpoints';
import { getCookie } from './utils/cookies';
import { Benefit } from './components/benefit';

const AuthorizedApp = lazyLoad(
  () => import('./authorized-app'),
  (module) => module.AuthorizedApp
);

const UnauthorizedApp = lazyLoad(
  () => import('./unauthorized-app'),
  (module) => module.UnauthorizedApp
);

const QrRedirectApp = lazyLoad(
  () => import('./qr-redirect-app'),
  (module) => module.QrRedirectApp
);

const VKRedirectApp = lazyLoad(
  () => import('./pages/vk-redirect'),
  (module) => module.VkRedirect
);

//TODO: авторизация под "персоной" (когда не добавлено адресов)

// const PersonAuthorizedApp = lazyLoad(
//   () => import('./person-authorized-app'),
//   (module) => module.PersonAuthorizedApp
// );

export const App = () => {
  const isPC = useMediaQuery({
    query: `(${MFMedia.smallPC})`,
  });

  const [isQrPayment, setIsQrPayment] = useState<boolean>(false);
  const [isVK, setIsVK] = useState<boolean>(false);
  const link = window.location.href;
  useEffect(() => {
    /** Определение использования QrRedirectApp  **/
    // URL первого шага: http://localhost:9000/#/qr/payment/phone/
    if (link.includes('qr/payment')) {
      setIsQrPayment(true);
      setIsVK(false);
    } else if (link.includes('pay_from_vk')) {
      setIsVK(true);
      setIsQrPayment(false);
    } else {
      setIsVK(false);
      setIsQrPayment(false);
    }
  }, [link]);

  const { onLoad, favImage } =
    useSelector(getAuthStateSelector);

  const getContent = () => {
    switch (true) {
      case onLoad:
        return 'Загрузка...';
      case isQrPayment && !isPC:
        return <QrRedirectApp />;
      case isVK:
        return <Route path={'/pay_from_vk/:id'} component={VKRedirectApp} />;
      case !!getCookie('csrftoken'):
        return <AuthorizedApp />
      default:
        return <UnauthorizedApp />
    }
  }

  return (
    <>
      <Helmet>
        <link rel="icon" href={favImage} type="image/png" sizes="16x16" />
        <meta
          name={'description'}
          content={`Кабинет-жителя.рф - сайт и приложение для жителей.
                Оплачивайте коммунальные услуги, вносите показания счётчиков, 
                подавайте заявки и обращения через мобильный телефон или планшет,
                просматривайте актуальные новости от Вашей управляющей организации.`}
        />
      </Helmet>
      <BrowserRouter basename={'#'}>
        <ThemeProvider theme={mainTheme}>
          <Loader />
          <AuthLoader />
          <Warning />
          <TimeWarning />
          <Benefit />
          <ServerError />
            {getContent()}
        </ThemeProvider>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};
