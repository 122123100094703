import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IConfig, IConfigType, ISettingsStep } from './types';
import {
  changeConfig,
  getCurrentConfig,
  getQueryUrl,
  sendWrongAddress,
} from '../../api/notifications';

type InitialState = {
  settingsStep: ISettingsStep;
  config: IConfig;
  configType: IConfigType;
  queryParams?: { [key: string]: string };
};

export const initialState: InitialState = {
  settingsStep: 'channels',
  config: {
    email: false,
    mobile_app: false,
    telegram: false,
    vk: false,
  },
  configType: 'off',
};

export const getNotificationsChannels = createAsyncThunk<
  any,
  { tenant_id: string } | undefined,
  any
>(
  'notifications/getNotificationsChannels',
  async (params, { rejectWithValue }) => {
    try {
      return await getCurrentConfig(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const changeCurrentChannels = createAsyncThunk<
  any,
  {
    tenant_id?: string;
    email: boolean;
    mobile_app: boolean;
    telegram: boolean;
    vk: boolean;
  },
  any
>(
  'notifications/changeCurrentChannels',
  async (params, { rejectWithValue }) => {
    try {
      return await changeConfig(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const rejectNotifications = createAsyncThunk<
  any,
  { tenant_id: string } | undefined,
  any
>('notifications/rejectNotifications', async (params, { rejectWithValue }) => {
  try {
    return await sendWrongAddress(params);
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const getUnauthorizedURL = createAsyncThunk<any, { id: string }, any>(
  'notifications/getUnauthorizedURL',
  async (params, { rejectWithValue }) => {
    try {
      return await getQueryUrl(params.id);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const NotificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setSettingsStep: (state, action: PayloadAction<ISettingsStep>) => {
      state.settingsStep = action.payload;
    },
    setConfigType: (state, action: PayloadAction<IConfigType>) => {
      state.configType = action.payload;
    },
    setQueryParams: (
      state,
      action: PayloadAction<{ [key: string]: string }>
    ) => {
      state.queryParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNotificationsChannels.fulfilled,
      (state, action: PayloadAction<IConfig>) => {
        state.config = action.payload;
        state.configType =
          (Object.values(action.payload).includes(true) && 'enable') || 'off';
      }
    );
  },
});

export const { setSettingsStep, setConfigType, setQueryParams } =
  NotificationsSlice.actions;

export default NotificationsSlice.reducer;
