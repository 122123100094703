import { clone, find } from 'lodash';
import moment, { Moment } from 'moment';

import { IDictConstant } from '../types';

export const getConstantValue = (constants: IDictConstant[], value: string) =>
  find(constants, { value })?.text || '';

export const getPhoneCode = (phone: string) => phone.slice(3, 6);

export const getPhoneNumber = (phone: string) =>
  phone.slice(7, 18).replace(/-/g, '').split(' ').join('');

export const splitPhoneNumber = (number: string) => {
  return `${number.slice(0, 3)}-${number.slice(3, 5)}-${number.slice(5, 7)}`;
};

export const toRubles = (num: number | string) => {
  const x = +num;
  const result = (x / 100).toFixed(2);
  return +result;
};

export const toFractionRubles = (value: number | string) => {
  const num = toRubles(value).toFixed(2);
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

// Маска для ввода денежного значения в формате "100'000'000.25" в input
export const maskRublesInput = (inputValue: string, maxAmount: number) => {
  let formattedValue;

  if (inputValue === '') {
    return { value: '', displayValue: '', isError: false };
  }

  // Добавляется '0', если строка начинается с точки/запятой
  if (/^[,.]/.test(inputValue)) {
    inputValue = '0' + inputValue;
  }

  // Удаляются все символы, кроме цифр, точки и запятой
  inputValue = inputValue.replace(/[^\d,.]|^0(?=\d)/g, '');

  // Удаляются все точки/запятые, если пользователь пытается ввести больше одного разделителя
  const firstDotIndex = inputValue.search(/[,.]/g);
  if (firstDotIndex !== -1) {
    inputValue =
      inputValue.slice(0, firstDotIndex + 1) +
      inputValue.slice(firstDotIndex + 1).replace(/[,.]/g, '');
  }

  // Заменяются запятые на точки
  inputValue = inputValue.replace(/,/g, '.');

  // Форматируется значения, добавляя пробелы как разделители тысяч и ограничивая ввод до сотых
  if (inputValue.indexOf('.') >= 0) {
    let [leftSide, rightSide] = inputValue.split('.');

    inputValue = leftSide + '.' + (rightSide.substr(0, 2) || '');
    formattedValue =
      leftSide.replace(/\B(?=(\d{3})+(?!\d))/g, `'`) +
      '.' +
      (rightSide.substr(0, 2) || '');
  } else {
    formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, `'`);
  }

  // Проверяется, превышает ли введенное значение максимально допустимое
  const isError = +inputValue > maxAmount;

  // Возвращается числовое значение, отформатированное (для отображения) и ошибка (true/false)
  return { value: inputValue, displayValue: formattedValue, isError };
};

export const formatDateToISO = (date: any): string => {
  // if (date && date instanceof Object) {
  //   date = date.isValid && date.isValid() ? date : moment();
  // }
  date = date || moment();
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const formatISODate = (date: any, format: string) => {
  if (date && date instanceof Object) {
    date = date.isValid && date.isValid() ? date : moment();
  }
  date = date || moment();
  return moment(date, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').locale('ru').format(format);
};

export const getDeadlineTime = (finish_date: Date) => {
  const fd = finish_date;
  const now = new Date();
  const deadline_time = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  let difference = Math.floor(fd.valueOf() - now.valueOf()) / 1000; //Разница в секундах

  deadline_time.years =
    Math.floor(difference / 31536000) > 0
      ? Math.floor(difference / 31536000)
      : 0;
  difference -= deadline_time.years * 31536000;

  deadline_time.months =
    Math.floor(difference / 2629744) > 0 ? Math.floor(difference / 2629744) : 0;
  difference -= deadline_time.months * 2629744;

  deadline_time.days =
    Math.floor(difference / 86400) > 0 ? Math.floor(difference / 86400) : 0;
  difference -= deadline_time.days * 86400;

  deadline_time.hours =
    Math.floor(difference / 3600) > 0 ? Math.floor(difference / 3600) : 0;
  difference -= deadline_time.hours * 3600;

  deadline_time.minutes =
    Math.floor(difference / 60) > 0 ? Math.floor(difference / 60) : 0;
  difference -= deadline_time.minutes * 60;

  deadline_time.seconds =
    Math.floor(difference) > 0 ? Math.floor(difference) : 0;

  return deadline_time;
};

/* Получение времени просрочки формата 1 г. 1 м. 1 д. */
export const getOverdueDurationStr = (
  initialDate: Moment, // Допустим, сейчас
  overdueDate: Moment // Допустим, это дата, к которой нужно было оплатить, и мы выясняем на сколько оплату просрочили
) => {
  const a = clone(initialDate);
  const b = clone(overdueDate);
  const years = a.diff(b, 'year');
  b.add(years, 'years');

  const months = a.diff(b, 'months');
  b.add(months, 'months');

  const days = a.diff(b, 'days');

  return `${years ? years + 'г.' : ''} ${months ? months + 'м.' : ''} ${
    days ? days + 'д.' : ''
  }`;
};

/* Получение рандомного целого числа: максимум не включается, минимум включается */
export const getRandomInt = (minNumber: number, maxNumber: number) => {
  const min = Math.ceil(minNumber);
  const max = Math.floor(maxNumber);
  return Math.floor(Math.random() * (max - min)) + min;
};

/* Получение строки с датой (вчера, х-дней назад, дата) */
export const getDateString = (_date: any, format?: string) => {
  if (!_date) {
    return '';
  }

  const date = moment(_date).startOf('day');
  const now = moment().startOf('day');

  const diff = now.diff(date, 'days');

  switch (true) {
    case diff === 0:
      return `Сегодня в ${moment(_date).format('HH:mm')}`;
    case diff === 1:
      return 'Вчера';
    case diff >= 2 && diff <= 4:
      return `${diff} дня назад`;
    case diff >= 5 && diff <= 10:
      return `${diff} дней назад`;
    default:
      return moment(_date).format('DD.MM.YYYY');
  }
};

/* Получения списка всех дат в периоде */
export const getDateRangeList = ({
  dateFrom,
  dateTill,
  format,
  monthList,
}: {
  dateFrom: string;
  dateTill: string | Moment;
  format: string;
  monthList?: string[];
}): string[] => {
  if (!monthList) {
    monthList = [moment(dateTill).format(format)];
  }
  const nextDate = moment(dateTill).subtract(1, 'month');
  if (nextDate >= moment(dateFrom)) {
    monthList.push(nextDate.format(format));
    return getDateRangeList({
      dateFrom,
      dateTill: nextDate,
      format,
      monthList,
    });
  } else {
    return monthList;
  }
};

// склонение чисел
export const countForm = (
  number: number,
  titles: [string, string, string]
): string => {
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
};

// замена \n на <br/>
export const replaceTextAreaNewLine = (str: string) => {
  return str.replace(/\n/g, '<br/>');
};

// преобразование 11 цифр номера телефона к формату +7 123 456-78-90
export const formatPhone = (phone: string) => {
  return `+${phone.slice(0, 1)} ${phone.slice(1, 4)} ${phone.slice(4, 7)}-${phone.slice(7, 9)}-${phone.slice(9, 11)}`;
};

// проверка валидности email
export const checkValidEmail = (email: string) => {
  const re =
    /^[A-Za-z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@[a-z0-9]{1,256}\.[a-z]{1,6}$/i;
  return re.test(email);
};

export const replaceEmojis = (str: string) => {
  return str.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    ''
  );
};

export const FormatKinds = (data: any) => {
  const tree = data.inheritors;

  if (tree) {
    let formatted = [];
    let full = [];

    for (let key in tree) {
      formatted.push({
        label: tree[key].name,
        value: key,
      });
      full.push(tree[key]);
    }

    return {
      formatted: formatted,
      full: full,
    };
  } else {
    return {
      formatted: [],
      full: [],
    };
  }
};
