import { combineReducers, configureStore } from '@reduxjs/toolkit';

import accrualsReducer, {
  initialState as accrualsInitialState,
} from '../pages/accruals/accruals-slice';

import authReducer, {
  initialState as authInitialState,
  InitialState as IAuthInitialState,
} from '../pages/auth/auth-slice';

import metersReducer, {
  initialState as metersInitialState,
} from '../pages/meters/meters-slice';

import newsReducer, {
  initialState as newsInitialState,
} from '../pages/news/news-slice';

import paymentsReducer, {
  initialState as paymentsInitialState,
} from '../pages/payments/payments-slice';

import profileReducer, {
  initialState as profileInitialState,
} from '../pages/profile/profile-slice';

import requestsReducer, {
  initialState as requestsInitialState,
} from '../pages/requests/requests-slice';

import ticketsReducer, {
  initialState as ticketsInitialState,
} from '../pages/tickets/tickets-slice';

import qrPaymentReducer, {
  initialState as qrPaymentInitialState,
} from '../pages/qr-payment/qr-payment-slice';

import catalogReducer, {
  initialState as catalogInitialState,
} from '../pages/catalog/catalog-slice';

import templateRequestReducer, {
  initialState as templateRequestInitialState,
} from '../pages/requests/tabs/template-request/template-request-slice';

import notificationReducer, {
  initialState as notificationsInitialState,
} from '../pages/notifications/notifications-slice';

import { errorMiddleware } from './middlewares/error-middleware';

import { loaderMiddleware } from './middlewares/loader-middleware';

import rootSliceReducer, {
  initialState as rootInitialState,
} from './root-slice';

export const rootReducer = combineReducers({
  root: rootSliceReducer,
  auth: authReducer,
  tickets: ticketsReducer,
  requests: requestsReducer,
  profile: profileReducer,
  meters: metersReducer,
  news: newsReducer,
  accruals: accrualsReducer,
  payments: paymentsReducer,
  qrPayment: qrPaymentReducer,
  template_request: templateRequestReducer,
  catalog: catalogReducer,
  notifications: notificationReducer,
});

const loadState = () => {
  // тут мог быть localStorage
  return false;
  return {} as IAuthInitialState;
};

const store = configureStore({
  preloadedState: {
    root: rootInitialState,
    auth: loadState() || authInitialState,
    tickets: ticketsInitialState,
    requests: requestsInitialState,
    // template_request (бывший каталог) сделан отдельным стором, возможно стоит всё перенести в requests
    template_request: templateRequestInitialState,
    profile: profileInitialState,
    meters: metersInitialState,
    news: newsInitialState,
    accruals: accrualsInitialState,
    payments: paymentsInitialState,
    qrPayment: qrPaymentInitialState,
    catalog: catalogInitialState,
    notifications: notificationsInitialState,
  },
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    loaderMiddleware,
    errorMiddleware,
  ],
});

export type RootState = ReturnType<typeof rootReducer>;
export default store;
