import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  // AddToFavorite,
  // DeleteFromFavorite,
  GetCatalogServicesList,
  GetCatalogCategoriesList,
  PostCatalogOrder,
  PostOrderLink,
  PostShowPhoneForStatistic,
  GetPreviewService,
  GetPreviewFile,
} from '../../api/catalog';
import { RootState } from 'app/store/store';
import { IService, ICurrentOrder, IServiceListResponse } from './types';

export interface InitialState {
  list: IServiceListResponse;
  categories: any[];
  // filtered: IService[];
  filtered: IServiceListResponse;
  serviceItemFullCard: boolean;
  currentService: any; // TODO временно для preview убран IService
  currentCategory: {
    text: string;
    value: string;
  } | null;
  currentOrder: ICurrentOrder;
  postOrderResponse: any;
  searchValue: string;
  previewImageLinks: any;
  statuses: {
    list: {
      onLoad: boolean;
    };
    categories: {
      onLoad: boolean;
    };
    filtered: {
      onLoad: boolean;
      loaded: boolean;
    };
    order: {
      onLoad: boolean;
    };
    favorite: {
      onLoad: boolean;
    };
  };
}

// TODO были изменения на backend + все что связанно с избранным будет меняться
// // сортировка избранного и услуг
// // избранные услуги перед остальными (+ отсортированы по имени)
// const listWithFavoriteFirst = (array: IService[]) => {
//   const favorite: IService[] = [];
//   const notFavorite: IService[] = [];
//   array.forEach((service: IService) =>
//     service.favorite ? favorite.push(service) : notFavorite.push(service)
//   );
//   favorite.sort((a, b) => a.title.localeCompare(b.title));
//   notFavorite.sort((a, b) => a.title.localeCompare(b.title));
//   return [...favorite, ...notFavorite];
// };

// добавление информации о компании в услугу
const setServiceCompany = (res: any) => {
  const allServices = [...res.favorite, ...res.services];
  return {
    services: allServices.map((service: any) => ({
      ...service,
      executor: res.executors[service.executor] || null,
    })),
    count: res.count,
    offset: res.offset,
  };
};

export const initialState: InitialState = {
  list: {
    services: [],
    params: {
      limit: 10,
      offset: 0,
    },
    count: 0,
  },
  categories: [],
  filtered: {
    services: [],
    params: {
      limit: 10,
      offset: 0,
    },
    count: 0,
  },
  serviceItemFullCard: false,
  searchValue: '',
  currentService: {
    id: '',
    title: '',
    description: '',
    // favorite: false,
    // provider: null,
    // parent: null,
    executor: null,
    position: null,
    images: [],
    image_cover: {
      id: null,
      content_type: '',
      file: '',
      name: '',
      filename: null,
      uuid: null,
    },
    contact_details: {
      name: '',
      address: '',
      phone: '',
      mail: '',
      comment: '',
    },
    price: {
      value: NaN,
      sale: NaN,
      sale_type: '',
      price_type: '',
    },
    media: [],
    contact_executor: {
      email: null,
      link: null,
    },
  },
  currentCategory: null,
  currentOrder: {
    title: '',
    name: '',
    address: '',
    phone: '',
    mail: '',
    comment: '',
  },
  postOrderResponse: '',
  previewImageLinks: [],
  statuses: {
    list: {
      onLoad: false,
    },
    categories: {
      onLoad: false,
    },
    filtered: {
      onLoad: false,
      loaded: false,
    },
    order: {
      onLoad: false,
    },
    favorite: {
      onLoad: false,
    },
  },
};

export const getCatalogServicesList = createAsyncThunk<any>(
  'catalog/getCatalogServicesList',
  async (_, { rejectWithValue, getState }) => {
    try {
      const { catalog } = getState() as RootState;
      const response = await GetCatalogServicesList(catalog.list.params);
      return setServiceCompany(response);
      // return listWithFavoriteFirst(response.results);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCatalogCategoriesList = createAsyncThunk<any>(
  'catalog/getCatalogCategoriesList',
  async (listType, { rejectWithValue }) => {
    try {
      const response = await GetCatalogCategoriesList({});
      return [
        { text: 'Все категории', value: 'all' },
        ...response.results.CompendiumPositionConstant,
      ];
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getCatalogServicesBySearch = createAsyncThunk<
  any,
  { title?: string; position?: string | null },
  any
>(
  'catalog/getCatalogServicesBySearch',
  async (params, { rejectWithValue, getState }) => {
    const { catalog } = getState() as RootState;
    if (params.position === 'all' || !params.position) {
      delete params.position;
    }
    try {
      const response = await GetCatalogServicesList({
        ...params,
        limit: catalog.filtered.params.limit,
        offset: catalog.filtered.params.offset,
      });
      return setServiceCompany(response);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const postCatalogOrder = createAsyncThunk<any>(
  'catalog/postCatalogOrder',
  async (listType, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const params: any = {
      ...state.catalog.currentOrder,
      ...{
        service_id: state.catalog.currentService.id,
        title: state.catalog.currentService.title,
        executor_mail: state.catalog.currentService.contact_executor.email,
      },
    };
    Object.keys(params).forEach((key: any) => {
      if (!params[key]) delete params[key];
    });
    try {
      return await PostCatalogOrder(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const postOrderLink = createAsyncThunk<any>(
  'catalog/postOrderLink',
  async (listType, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    const params = {
      link: state.catalog.currentService.contact_executor.link,
    };
    try {
      return await PostOrderLink(state.catalog.currentService.id, params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const postShowPhoneForStatistic = createAsyncThunk<
  any,
  { serviceId: string },
  any
>(
  'catalog/postShowPhoneForStatistic',
  async ({ serviceId }, { rejectWithValue }) => {
    try {
      return await PostShowPhoneForStatistic(serviceId, {});
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPreviewService = createAsyncThunk<any, { srv: string }, any>(
  'catalog/getPreviewService',
  async ({ srv }, { rejectWithValue, getState }) => {
    try {
      return await GetPreviewService(srv);

      // state.currentService = response;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getPreviewFile = createAsyncThunk<
  any,
  { srv: string; item: string },
  any
>(
  'catalog/getPreviewFile',
  async ({ srv, item }, { rejectWithValue, getState }) => {
    try {
      return await GetPreviewFile(srv, item);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

// TODO были изменения на backend + все что связанно с избранным будет меняться
// export const setFavorite = createAsyncThunk<
//   any,
//   { id: string; method: 'add' | 'delete' },
//   any
// >(
//   'catalog/addToFavorite',
//   async ({ id, method }, { rejectWithValue, getState }) => {
//     try {
//       const state = getState() as RootState;
//       const response =
//         method === 'add'
//           ? await AddToFavorite(id)
//           : await DeleteFromFavorite(id);
//
//       // обновляем услугу в списке и текущей открытой услуге
//       const serviceInListIndex = state.catalog.list.findIndex(
//         (el: IService) => el.id === id
//       );
//       const copyList = clone(state.catalog.list);
//       copyList.splice(serviceInListIndex, 1);
//       copyList.splice(serviceInListIndex, 0, response);
//       const newList = listWithFavoriteFirst(copyList);
//       return {
//         currentService: response,
//         list: newList,
//       };
//     } catch (e) {
//       return rejectWithValue(e);
//     }
//   }
// );

export const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setCurrentService: (state, action) => {
      state.currentService = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setCurrentOrderField: (state, action) => {
      state.currentOrder = { ...state.currentOrder, ...action.payload };
    },
    clearCurrentService: (state) => {
      state.currentService = initialState.currentService;
    },
    clearPostOrderResponse: (state) => {
      state.postOrderResponse = initialState.postOrderResponse;
    },
    clearCatalogServicesBySearch: (state) => {
      state.filtered = initialState.filtered;
    },
    clearCurrentOrder: (state) => {
      state.currentOrder = initialState.currentOrder;
    },
    showServiceItemFullCard: (state, action) => {
      state.serviceItemFullCard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCatalogServicesList.pending, (state, action) => {
      state.statuses.list.onLoad = true;
    });

    builder.addCase(getCatalogCategoriesList.pending, (state, action) => {
      state.statuses.categories.onLoad = true;
    });

    builder.addCase(getCatalogServicesBySearch.pending, (state, action) => {
      state.statuses.filtered.loaded = false;
      state.statuses.filtered.onLoad = true;
    });

    builder.addCase(postCatalogOrder.pending, (state, action) => {
      state.statuses.order.onLoad = true;
    });

    builder.addCase(postOrderLink.pending, (state, action) => {
      state.statuses.order.onLoad = true;
    });

    // builder.addCase(setFavorite.pending, (state, action: any) => {
    //   state.statuses.favorite.onLoad = true;
    // });

    builder.addCase(getCatalogServicesList.rejected, (state, action) => {
      state.statuses.list.onLoad = false;
    });

    builder.addCase(getCatalogCategoriesList.rejected, (state, action) => {
      state.statuses.categories.onLoad = false;
    });

    builder.addCase(getCatalogServicesBySearch.rejected, (state, action) => {
      state.statuses.filtered.onLoad = false;
    });

    builder.addCase(postCatalogOrder.rejected, (state, action) => {
      state.statuses.order.onLoad = false;
    });

    builder.addCase(postOrderLink.rejected, (state, action) => {
      state.statuses.order.onLoad = false;
    });

    // builder.addCase(setFavorite.rejected, (state, action: any) => {
    //   state.statuses.favorite.onLoad = false;
    // });

    builder.addCase(
      getCatalogServicesList.fulfilled,
      (
        state,
        action: PayloadAction<{
          services: IService[];
          count: number;
          offset: number;
        }>
      ) => {
        state.list = {
          services: [...state.list.services, ...action.payload.services],
          params: {
            ...state.list.params,
            offset: state.list.params.offset + state.list.params.limit,
          },
          count: action.payload.count,
        };
        state.statuses.list.onLoad = false;
      }
    );

    builder.addCase(
      getCatalogCategoriesList.fulfilled,
      (state, action: PayloadAction<IService[]>) => {
        state.categories = action.payload;
        state.statuses.categories.onLoad = false;
      }
    );

    builder.addCase(
      getCatalogServicesBySearch.fulfilled,
      (
        state,
        action: PayloadAction<{
          services: IService[];
          count: number;
          offset: number;
        }>
      ) => {
        state.filtered = {
          services: action.payload.services,
          params: {
            ...state.filtered.params,
            offset:
              action.payload.services.length >= state.filtered.params.limit
                ? state.filtered.params.offset + state.filtered.params.limit
                : 0,
          },
          count: action.payload.count,
        };
        state.statuses.filtered.onLoad = false;
        state.statuses.filtered.loaded = true;
      }
    );

    builder.addCase(
      postCatalogOrder.fulfilled,
      (state, action: PayloadAction) => {
        state.postOrderResponse = action.payload;
        state.statuses.order.onLoad = false;
      }
    );

    builder.addCase(postOrderLink.fulfilled, (state, action: PayloadAction) => {
      state.postOrderResponse = action.payload;
      state.statuses.order.onLoad = false;
    });

    builder.addCase(
      getPreviewService.fulfilled,
      (state, action: PayloadAction) => {
        state.currentService = action.payload;
      }
    );

    builder.addCase(
      getPreviewFile.fulfilled,
      (state, action: PayloadAction) => {
        state.previewImageLinks = [...state.previewImageLinks, action.payload];
      }
    );

    // builder.addCase(
    //   setFavorite.fulfilled,
    //   (
    //     state,
    //     action: PayloadAction<{
    //       currentService: IService;
    //       list: IService[];
    //     }>
    //   ) => {
    //     const { currentService, list } = action.payload;
    //     state.currentService = currentService;
    //     state.list = list;
    //     state.statuses.favorite.onLoad = false;
    //   }
    // );
  },
});

export const {
  setCurrentService,
  setCurrentCategory,
  clearCurrentService,
  clearPostOrderResponse,
  clearCatalogServicesBySearch,
  setCurrentOrderField,
  clearCurrentOrder,
  showServiceItemFullCard,
  setSearchValue,
} = catalogSlice.actions;

export default catalogSlice.reducer;
