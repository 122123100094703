import styled from 'styled-components/macro';
import { ResetButtonMixin } from 'app/styles/mixins';

export const Title = styled.div`
  font-size: 17px;
  font-weight: 500;
  margin-top: 20px;
`;

export const Body = styled.div`
  color: #333333;
  font-size: 14px;
  font-weight: 300;
  padding: 20px;
`;

export const ButtonGroup = styled.div<{ $isAlert: boolean }>`
  display: flex;
  flex-flow: row nowrap;

  button {
    color: #828282;
    border-top: 0.5px solid #dadada;
    flex: 1;
    height: 40px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;

    &:last-child {
      color: #30a9f8;
      border-left: ${(props) =>
        props.$isAlert ? 'none' : '0.5px solid #dadada'};
    }

    ${ResetButtonMixin};
  }
`;

export const Fade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: -webkit-fill-available;
  height: -moz-available;
  width: -webkit-fill-available;
  width: -moz-available;
  opacity: 0.2;
  background-color: #000000;
  z-index: 400;
`;

export const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  background-color: #ffffff;
  border-radius: 15px;
  text-align: center;
  width: 100%;
  max-width: 290px;
  z-index: 400;
`;
