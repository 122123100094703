import { Mutex } from 'async-mutex';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const refresh_tokens_mutex = new Mutex();
const logout_mutex = new Mutex();
const { AUTH } = GW_SERVICES_PREFIXES;

const relocateToAuthPageWithReload = () => {
  document.location.assign(`${document.location.origin}/#/auth/login/`);
  // Вместо очистки всех данных в store
  document.location.reload();
};

export class ResponseError extends Error {
  public response: Response;
  public errorMessage: string[] = [];

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}

/**
 * Parses the JSON returned by a network request
 *
 * @param {Response} response A response from a network request
 * @return {object | null} The parsed JSON from the request or null for specific statuses
 */
function parseJSON(response: Response): object | null {
  if (
    response.status === 204 ||
    response.status === 205 ||
    (response.url.includes('acquiring/fast_transaction') &&
      response.status === 200)
  ) {
    return null;
  }
  return response.json();
}

/**
 * Refresh the token if necessary and retry the request
 *
 * @param {string} url The original request URL
 * @param {RequestInit} options The options for the original request
 * @return {Promise<Response>} The response after the retry
 */
async function refreshTokenAndRetry(url: string, options?: RequestInit): Promise<Response> {
  const release = await refresh_tokens_mutex.acquire();
  try {
    const refreshResponse = await fetch(`/${AUTH}/api/v1/auth/refresh/`, {
      method: 'POST',
      credentials: 'include',
    });

    if (refreshResponse.status === 200) {
      return fetch(url, options);
    } else {
      throw new Error('Failed to refresh token');
    }
  } finally {
    release();
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param {string} url The URL we want to request
 * @param {RequestInit} [options] The options we want to pass to "fetch"
 * @return {Promise<any>} The response data
 */
export async function request(url: string, options?: RequestInit): Promise<any> {
  /*
    async-mutex используется для потокобезопасности при запросе обновления
    токенов
    https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
    https://github.com/DirtyHairy/async-mutex
  */
  await refresh_tokens_mutex.waitForUnlock();
  let fetchResponse = await fetch(url, options);
  const contentType = fetchResponse.headers.get('content-type');

  if (contentType) {
    if (!fetchResponse.ok && contentType.includes('application/json')) {
      const body = await fetchResponse.clone().json();
      const { detail } = body;

      if (fetchResponse.status === 401) {
        if (
          [
            'Tokens need to be updated',
            'Given token not valid for any token type',
          ].includes(detail)
        ) {
          // Handle token refresh
          if (!refresh_tokens_mutex.isLocked()) {
            fetchResponse = await refreshTokenAndRetry(url, options);
          } else {
            await refresh_tokens_mutex.waitForUnlock();
            fetchResponse = await fetch(url, options);
          }
        } else if (detail === 'Not authenticated' && !logout_mutex.isLocked()) {
          logout_mutex.acquire().then((release) => {
              /*
                В случае такого response, приходят 3 set-cookie c удалением
                access_token, refresh_token и csrftoken
              */
            relocateToAuthPageWithReload();
            release();
          });
        }
      } else {
        // Из старого frontend
        const error = new ResponseError(fetchResponse);
        if ([406, 409].includes(fetchResponse.status)) {
          const message = await body.errors;
          error.errorMessage = message.errors || [];
        }
        throw error;
      }
    } else if (contentType.includes('text/html')) {
      if (!fetchResponse.ok) {
        throw new ResponseError(fetchResponse);
      }
      // Текстовое содержимое для ответов HTML
      return fetchResponse.text();
    }
  }

  if (fetchResponse.ok) {
    return parseJSON(fetchResponse);
  } else {
    throw new ResponseError(fetchResponse);
  }
}