import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetNotices } from 'app/api/accruals';
import {
  AddTenantPhone,
  ChangeTenantPassword,
  CheckTenantPassword,
  GetProfileInfo,
  GetTenantPhones,
  GetReceiptsInfo,
  UpdateReceiptsInfo,
  ReChangeTenantEmail,
  RemoveTenantPhone,
  getInsuranceList,
  ChangeAccountEmail,
  DeleteProfile,
} from 'app/api/profile';
import { showWarning } from 'app/store/root-slice';
import { RootState } from 'app/store/store';
import { IPhone } from '../../types';
import { IInsurance, INotices, IProfileInfo } from './types';

interface receiptEmail {
  id?: string;
  email_type: 'main' | 'additional';
  address: string;
}

export interface InitialState {
  notices: INotices;
  profileInfo: IProfileInfo;
  receiptsInfo: {
    tenant_id?: string;
    paper_bills: {
      disabled: boolean;
      print_anyway: false;
      disable_date?: boolean;
      disabler?: {
        account_type: 'super' | 'worker' | 'tenant';
        name?: string;
        description?: string;
      };
    };
    emails?: receiptEmail[];
    bills?: {
      doc_id: string;
      sector: string;
      download_token: string;
      token_lifetime: string;
      channels: string[];
      status: {
        sent?: {
          when: string;
          message?: string | null;
          target?: 'telegram' | 'email' | null;
        } | null;
        received?: {
          when: string;
          message?: string | null;
          target?: 'telegram' | 'email' | null;
        } | null;
        downloaded?: {
          when: string;
          message?: string | null;
          target?: 'telegram' | 'email' | null;
        } | null;
        failed?: {
          when: string;
          message?: string | null;
          target?: 'telegram' | 'email' | null;
        } | null;
      }[];
    }[];
    bills_channel?: string[];
  };
  phones: {
    onLoad: boolean;
    loaded: boolean;
    list: any[];
  };
  insurances: IInsurance[];
}

export const initialState: InitialState = {
  notices: {
    debts: [],
    last_pay_date: null,
    meters: [],
    requests: {
      house: 0,
      own: 0,
    },
    tickets: [],
    auto_payments: {},
    payments: {},
  },
  profileInfo: {} as IProfileInfo,
  receiptsInfo: {
    paper_bills: {
      disabled: false,
      print_anyway: false,
    },
  },
  phones: {
    onLoad: false,
    loaded: false,
    list: [],
  },
  insurances: [],
};

export const getNotices = createAsyncThunk(
  'profile/getNotices',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { auth } = getState() as RootState;
    const params = {
      sectors: auth.user.sectors,
    };
    try {
      return await GetNotices(params);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const getProfileInfo = createAsyncThunk<IProfileInfo>(
  'profile/getProfileInfo',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      return await GetProfileInfo();
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getTenantPhones = createAsyncThunk(
  'profile/getTenantPhones',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      return await GetTenantPhones();
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getReceiptsInfo = createAsyncThunk(
  'profile/getReceiptsInfo',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      return await GetReceiptsInfo();
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const updateReceiptsInfo = createAsyncThunk(
  'profile/updateReceiptsInfo',
  async (_, { rejectWithValue, dispatch, getState }) => {
    const { profile } = getState() as RootState;
    try {
      return await UpdateReceiptsInfo(profile.receiptsInfo);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const addTenantPhone = createAsyncThunk<any, IPhone, any>(
  'profile/addTenantPhone',
  async (phone, { rejectWithValue, dispatch, getState }) => {
    try {
      return await AddTenantPhone(phone);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const removeTenantPhone = createAsyncThunk<any, string, any>(
  'profile/removeTenantPhone',
  async (phoneId, { rejectWithValue, dispatch, getState }) => {
    try {
      return await RemoveTenantPhone(phoneId);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const changeAccountEmail = createAsyncThunk<any, string, any>(
  'profile/changeAccountEmail',
  async (email, { rejectWithValue, dispatch }) => {
    try {
      return await ChangeAccountEmail(email);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const reChangeTenantEmail = createAsyncThunk(
  'profile/reChangeTenantEmail',
  async (_, { rejectWithValue, dispatch, getState }) => {
    try {
      return await ReChangeTenantEmail();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const checkTenantPassword = createAsyncThunk<any, string, any>(
  'profile/checkTenantPassword',
  async (password, { rejectWithValue, dispatch, getState }) => {
    try {
      return await CheckTenantPassword(password);
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const changeTenantPassword = createAsyncThunk<any, string, any>(
  'profile/changeTenantPassword',
  async (password, { rejectWithValue, dispatch, getState }) => {
    try {
      return await ChangeTenantPassword(password);
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const deleteProfile = createAsyncThunk(
  'profile/deleteProfile',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      return await DeleteProfile().then(() => {
        // При удалении единственного профиля, вернется 204 статус без content.
        // В ином случае обновятся cookies с токенами через response и
        // необходимо обновить всю информацию по жителю.
        document.location.reload();
      });
    } catch (e) {
      dispatch(showWarning());
      return rejectWithValue(e);
    }
  }
);

export const getDataInsurance = createAsyncThunk(
  'profile/get_insurances',
  async (_, { rejectWithValue }) => {
    try {
      return await getInsuranceList();
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateReceiptsState: (state) => {
      state.receiptsInfo.paper_bills.disabled =
        !state.receiptsInfo.paper_bills.disabled;
    },
    updateRecipentsEmails: (state, action) => {
      let emails = action.payload;
      if (emails.length) {
        emails = emails.filter((email: receiptEmail) => email.address.length);
      }
      state.receiptsInfo.emails = emails;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNotices.fulfilled,
      (state, { payload }: PayloadAction<INotices>) => {
        state.notices = payload;
      }
    );

    builder.addCase(
      getProfileInfo.fulfilled,
      (state, { payload }: PayloadAction<IProfileInfo>) => {
        state.profileInfo = payload;
      }
    );

    builder.addCase(getTenantPhones.fulfilled, (state, { payload }) => {
      state.phones.list = payload;
      state.phones.loaded = true;
      state.phones.onLoad = false;
    });

    builder.addCase(getTenantPhones.pending, (state, action) => {
      state.phones.onLoad = true;
      state.phones.loaded = false;
    });

    builder.addCase(getTenantPhones.rejected, (state, action) => {
      state.phones.onLoad = false;
      state.phones.loaded = false;
    });

    builder.addCase(getReceiptsInfo.fulfilled, (state, { payload }) => {
      state.receiptsInfo = payload;
    });

    builder.addCase(getDataInsurance.fulfilled, (state, { payload }) => {
      state.insurances = payload.insurance_list;
    });
  },
});

export const { updateRecipentsEmails, updateReceiptsState } =
  profileSlice.actions;

export default profileSlice.reducer;
