import { IRequestReading } from '../pages/meters/types';
import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const GetMeters = () => API.get(`/${INTERNAL}/api/v4/cabinet/meters/`);

export const SendReadings = (data: { meters: IRequestReading[] }) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/meters/`, data);

export const GetMetersReport = (params: {
  month_from: string;
  month_till: string;
}) => API.get(`/${INTERNAL}/api/v4/cabinet/meters_report/`, params);
