import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const GetRequestList = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/requests/`, params);

export const GetRequest = (requestId: string) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/requests/${requestId}/`);

export const CreateRequest = (data: any) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/requests/`, data);

export const UpdateRequest = (requestId: string, data: any) =>
  API.patch(`/${INTERNAL}/api/v4/cabinet/requests/${requestId}/`, data);

export const UpdateRequestFiles = (id: string, params: FormData) =>
  API.patchFiles(`/${INTERNAL}/api/v4/cabinet/requests/files/${id}/`, params);

export const GetKindsTree = () =>
  API.get(`/${INTERNAL}/api/v4/forms/requests/kinds_tree/`);

export const GetKindsDescriptions = () =>
  API.get(`/${INTERNAL}/api/v4/forms/requests/kinds_description/`);