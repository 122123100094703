import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { getProfileExtraDataSelector } from 'app/pages/auth/selectors';
import { useOldConfirm } from './use-old-confirm';

export const useRights = (slug: string, warnText?: string) => {
  const { permissions } = useSelector(getProfileExtraDataSelector);
  const { Confirm, openConfirm } = useOldConfirm({
    body: warnText || 'Недостаточно прав',
    type: 'alert',
    confirmText: 'Понятно',
    onConfirm: () => {},
  });

  const onClickHandler = (e: any) => {
    if (!permissions[slug]?.includes('R')) {
      e.preventDefault();
      e.stopPropagation();
      warnText && openConfirm();
    }
  };

  return {
    create : permissions?.[slug]?.includes('C'),
    read: permissions?.[slug]?.includes('R'),
    update: permissions?.[slug]?.includes('U'),
    delete: permissions?.[slug]?.includes('D'),
    onClickHandler,
    Protect: ({
      children,
    }: {
      children: ReactElement | ReactElement[];
    }) => (
      <>
        <Confirm />
        {children}
      </>
    ),
  };
};
