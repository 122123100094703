import { request } from '../utils/request';

// const apiUrl = process.env.NODE_ENV.trim() === 'production' ? '' : '';

const prepareParams = (data: any) => {
  /* Что бы если в параметрах был массив, то они выглядели бы как:
   * sector=rent&sector=heat_supply, а не sector=rent,heat_supply */
  const params = new URLSearchParams();

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];
      if (Array.isArray(value)) {
        value.map((val) => params.append(key, val));
      } else {
        params.append(key, value);
      }
    }
  }

  return params;
};

export const API = {
  get: (url: string, params?: any, extraHeaders?: { [key: string]: any }) =>
    request(url + (params ? `?${prepareParams(params)}` : ''), {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...(extraHeaders && extraHeaders),
      },
    }),
  post: (
    url: string,
    body?: { [key: string]: any },
    extraHeaders?: { [key: string]: any }
  ) =>
    request(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        ...(body && { 'Content-Type': 'application/json;charset=utf-8' }),
        ...(extraHeaders && extraHeaders),
      },
      ...(body && { body: JSON.stringify(body) }),
    }),
  delete: (url: string, params?: any, extraHeaders?: { [key: string]: any }) =>
    request(url + (params ? `?${prepareParams(params)}` : ''), {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        ...(extraHeaders && extraHeaders),
      },
    }),
  patch: (url: string, body?: any, extraHeaders?: { [key: string]: any }) =>
    request(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...(body && { 'Content-Type': 'application/json;charset=utf-8' }),
        ...(extraHeaders && extraHeaders),
      },
      ...(body && { body: JSON.stringify(body) }),
    }),
  patchFiles: (
    url: string,
    body: FormData,
    extraHeaders?: { [key: string]: any }
  ) =>
    request(url, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        ...(extraHeaders && extraHeaders),
      },
      body,
    }),
  put: (url: string, body?: object, extraHeaders?: { [key: string]: any }) =>
    request(url, {
      method: 'PUT',
      headers: {
        ...(body && { 'Content-Type': 'application/json;charset=utf-8' }),
        ...(extraHeaders && extraHeaders),
      },
      credentials: 'include',
      ...(body && { body: JSON.stringify(body) }),
    }),
};
