import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';
// import { ICurrentOrder } from '../pages/catalog/types';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const GetCatalogServicesList = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/compendium/all_service/`, params);

export const GetCatalogCategoriesList = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/models/compendium/position/`, params);

// export const GetCatalogServicesBySearch = (params: any) =>
//   API.get(`/compendium/all_service/`, params)

// params: ICurrentOrder не подойдут, т.к. будут удалены пустые поля
export const PostCatalogOrder = (params: any) =>
  API.post(`/${INTERNAL}/api/v4/compendium/order_service/send_mail/`, params);

export const AddToFavorite = (id: string) =>
  API.post(`/${INTERNAL}/api/v4/compendium/all_service/${id}/add_to_favorite/`);

export const DeleteFromFavorite = (id: string) =>
  API.delete(
    `/${INTERNAL}/api/v4/compendium/all_service/${id}/del_from_favorite/`,
    ''
  );

export const PostOrderLink = (positionId: string, params: any) =>
  API.post(
    `/${INTERNAL}/api/v4/compendium/order_service/${positionId}/follow_link/`,
    params
  );

export const PostShowPhoneForStatistic = (positionId: string, params: any) =>
  API.post(
    `/${INTERNAL}/api/v4/compendium/order_service/${positionId}/show_phone/`,
    params
  );

export const GetPreviewService = (key: string) =>
  API.get(`/${INTERNAL}/api/v4/compendium/preview/${key}/get_data/`);

export const GetPreviewFile = (key: string, itemName: string) =>
  API.get(
    `/${INTERNAL}/api/v4/compendium/preview/${key}/get_image/?image_name=${itemName}`
  );
