import { RootState } from 'app/store/store';

export const getAuthStateSelector = (state: RootState) => state.auth;
export const getUserInfoSelector = (state: RootState) => state.auth.user;
export const getConstantsSelector = (state: RootState) => state.auth.constants;
export const getAuthStatusesSelector = (state: RootState) =>
  state.auth.statuses;
export const getProfileExtraDataSelector = (state: RootState) =>
  state.auth.profileExtraData;
export const getProfilesSelector = (state: RootState) =>
  state.auth.profiles;
