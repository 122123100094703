import React, { useEffect, useRef } from 'react';
import { Video } from './service-video.styled';
import { IService } from '../../../../types';
import { GW_SERVICES_PREFIXES } from '../../../../../../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

interface Props {
  service: IService;
  file: any;
  changeSlide: boolean;
}

export const ServiceVideo = ({ service, file, changeSlide }: Props) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.pause();
    }
  }, [changeSlide]);

  return (
    <Video controls className="media-item" ref={ref}>
      <source
        src={`/${INTERNAL}/api/v4/compendium/file/${service.id}/?media=${file.file}`}
        type={file.content_type}
      />
    </Video>
  );
};
