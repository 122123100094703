import { IPhone } from '../types';
import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';
import { getCookie } from '../utils/cookies';

const { INTERNAL, AUTH } = GW_SERVICES_PREFIXES;

export const GetProfileInfo = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/tenant_profile/`);

export const GetTenantPhones = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/tenant_profile/phones/`);

export const GetReceiptsInfo = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/notifications/`);

export const UpdateReceiptsInfo = (receiptsInfo: { [key: string]: any }) =>
  API.patch(`/${INTERNAL}/api/v4/cabinet/notifications/update/`, receiptsInfo);

// export const ChangeAccountEmail = () =>
//   API.patch('/cabinet/notifications/update/', undefined, receiptsInfo);

export const AddTenantPhone = (phone: IPhone) =>
  API.put(`/${INTERNAL}/api/v4/cabinet/tenant_profile/phones/`, phone);

export const RemoveTenantPhone = (phoneId: string) =>
  API.get(
    `/${INTERNAL}/api/v4/cabinet/tenant_profile/phones/${phoneId}/not_actual/`
  );

export const ChangeAccountEmail = (email: string) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/change_email/`,
    {
      email,
    },
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const ReChangeTenantEmail = () =>
  API.get(
    `/${INTERNAL}/api/v4/cabinet/tenant_profile/send_activation_mail_again/`
  );

export const ChangeTenantPassword = (password: string) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/change_password/`,
    {password},
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const CheckTenantPassword = (password: string) => {
  const csrftoken = getCookie('csrftoken');
  return API.post(
    `/${AUTH}/api/v1/account/check_password/`,
    { password },
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

// Удаление профиля
export const DeleteProfile = () => {
  const csrftoken = getCookie('csrftoken');
  return API.delete(
    `/${AUTH}/api/v1/account/delete_profile/`,
    undefined,
    csrftoken ? { 'X-CSRFToken': csrftoken } : undefined
  );
};

export const getInsuranceList = () =>
  API.get(`/${INTERNAL}/api/v4/cabinet/insurance/`);
