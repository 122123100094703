import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useAppDispatch } from 'app/hooks';
import {
  getCurrentCatalogServiceOrderSelector,
  getCurrentCatalogServiceSelector,
  getServiceItemFullCardSelector,
} from '../../../../selectors';
import {
  clearCurrentService,
  clearCurrentOrder,
  // setFavorite,
  showServiceItemFullCard,
  postShowPhoneForStatistic,
} from '../../../../catalog-slice';
import {
  ServicePrice,
  // ServiceVideo,
  Executor,
  SendOrderBlock,
} from '../../components';
import { formatPhone, replaceTextAreaNewLine } from 'app/utils/getters';
import { GW_SERVICES_PREFIXES } from '../../../../../../constants/gateway';
import {
  ServiceWindow,
  ServiceNavTitle,
  SliderImg,
  SliderImgInner,
  ServiceTitle,
  // PositionCost,
  ServiceCardBlock,
  // Star,
  BlurContainter,
  ServiceWindowContainer,
  // VideoBlock,
  EmptyImg,
  NavWrapper,
  // ActualPrice,
  DescriptionTitle,
  ContactPhone,
  ContactPhoneBlock,
  ContactPhoneBtnPC,
  ContactPhoneBtnMobile,
  ToCreteBtn,
  ContactButtonsBlock,
  ContentBlock,
  ServiceTitleBlock,
  ServiceDescription,
  ReturnBtn,
  AliceCarouselMobile,
  AliceCarouselPC,
  AliceDotsBlock,
  AliceDot,
  SlidePrev,
  SlideNext,
  ArrowShortLeft,
  ArrowShortRight,
} from './service-item-full-card.styled';
import { Arrow, ArrowLeft } from '../../../../catalog.styled';
import { IService } from '../../../../types';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const ServiceItemFullCard = () => {
  const ref = useRef<HTMLButtonElement>(null);
  const refCarousel = useRef<AliceCarousel>(null);
  const dispatch = useAppDispatch();
  const currentService = useSelector(getCurrentCatalogServiceSelector); // открытая услуга
  const visibleServiceFullCard = useSelector(getServiceItemFullCardSelector);
  const currentOrder = useSelector(getCurrentCatalogServiceOrderSelector);
  const [changeSlide, setChangeSlide] = useState<boolean>(false);
  const [showContactPhone, setShowContactPhone] = useState<boolean>(false);
  const [serviceMediaWithCover, setServiceMediaWithCover] = useState<
    IService[]
  >([]);
  const [oldCurrentService, setOldCurrentService] = useState<string>('');

  useEffect(() => {
    // очистка при закрытии услуги через навигацию
    return () => handleCloseService();
  }, []);

  useEffect(() => {
    /* Добавление image_cover (при наличии) к media первым слайдом
     * а также явная пометка image_cover для определения ссылки в src
     */
    if (currentService.image_cover?.content_type.includes('image')) {
      const imageCover = { ...currentService.image_cover, isCover: true };
      // возможность добавления видео через интерфейс в lk временно убрана,
      // в старых услугах могут присутствовать видео в media
      const onlyImg = currentService.media.filter((file: any) =>
        file.content_type.includes('image')
      );
      setServiceMediaWithCover([imageCover, ...onlyImg]);
    } else {
      setServiceMediaWithCover(
        currentService.media.filter((file: any) =>
          file.content_type.includes('image')
        )
      );
    }
  }, []);

  const handleCloseService = () => {
    dispatch(clearCurrentService());
    dispatch(clearCurrentOrder());
    dispatch(showServiceItemFullCard(false));
  };

  const handleClick = () => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // отправка статистики
    if (showContactPhone && oldCurrentService !== currentService.id) {
      dispatch(postShowPhoneForStatistic({ serviceId: currentService.id }));
      setOldCurrentService(currentService.id);
    }
  }, [currentService.id, showContactPhone]);

  // const handleRate = () => {
  //   dispatch(
  //     setFavorite({
  //       id: currentService.id,
  //       method: currentService.favorite ? 'delete' : 'add',
  //     })
  //   );
  // };

  const services = serviceMediaWithCover.map((file: any, index: number) => {
    // if (file.content_type.includes('image')) {
    return (
      <SliderImg
        key={index}
        img={`/${INTERNAL}/api/v4/compendium/file/${currentService.id}/?${
          file.isCover ? 'image_cover' : 'media'
        }=${file.file}`}
      >
        <SliderImgInner
          img={`/${INTERNAL}/api/v4/compendium/file/${currentService.id}/?${
            file.isCover ? 'image_cover' : 'media'
          }=${file.file}`}
        />
      </SliderImg>
    );
    // }
    // if (file.content_type.includes('video')) {
    //   return (
    //     <VideoBlock key={index}>
    //       <ServiceVideo
    //         service={currentService}
    //         file={file}
    //         changeSlide={changeSlide}
    //       />
    //     </VideoBlock>
    //   );
    // }
  });

  return (
    <>
      {visibleServiceFullCard && (
        <ServiceWindowContainer>
          <BlurContainter onClick={handleCloseService} />
          <ServiceWindow>
            <Scrollbars
              autoHide
              renderView={(props) => (
                <div
                  {...props}
                  style={{ ...props.style, overflowX: 'hidden' }}
                />
              )}
            >
              <NavWrapper onClick={handleCloseService}>
                <Arrow style={{ left: '7px' }} />
                <ServiceNavTitle>Вернуться</ServiceNavTitle>
              </NavWrapper>
              <ReturnBtn onClick={handleCloseService}>
                <ArrowLeft />В каталог
              </ReturnBtn>
              <ServiceCardBlock>
                {!!currentService.media?.length ? (
                  <>
                    <AliceCarouselMobile>
                      <AliceCarousel
                        disableButtonsControls
                        disableDotsControls
                        mouseTracking
                        onSlideChanged={() => {
                          setChangeSlide(
                            (changeSlide: boolean) => !changeSlide
                          );
                        }}
                        autoPlayStrategy={'default'}
                        // 1.05 для небольшого предпросмотра следующего элемента карусели в текущем stage.
                        // Необходимо учесть, что пагинация через DotsControls и ButtonsControls (если она нужна) будет нарушена
                        responsive={{ 0: { items: 1.05 } }}
                      >
                        {services}
                      </AliceCarousel>
                    </AliceCarouselMobile>
                    <AliceCarouselPC>
                      <AliceCarousel
                        ref={refCarousel}
                        renderDotsItem={() => (
                          <AliceDotsBlock>
                            <AliceDot />
                          </AliceDotsBlock>
                        )}
                        onSlideChanged={() => {
                          setChangeSlide(
                            (changeSlide: boolean) => !changeSlide
                          );
                        }}
                        disableButtonsControls
                        autoPlayStrategy={'default'}
                        controlsStrategy={'responsive'}
                      >
                        {services}
                      </AliceCarousel>
                      <SlidePrev
                        onClick={(e) => refCarousel?.current?.slidePrev(e)}
                      >
                        <ArrowShortLeft />
                      </SlidePrev>
                      <SlideNext
                        onClick={(e) => refCarousel?.current?.slideNext(e)}
                      >
                        <ArrowShortRight />
                      </SlideNext>
                    </AliceCarouselPC>
                  </>
                ) : (
                  <EmptyImg />
                )}
                <ContentBlock>
                  <ServiceTitleBlock>
                    <ServiceTitle>{currentService.title}</ServiceTitle>
                    {/*<Star*/}
                    {/*  onClick={handleRate}*/}
                    {/*  className={*/}
                    {/*    currentService.favorite ? 'fas fa-star' : 'far fa-star'*/}
                    {/*  }*/}
                    {/*/>*/}
                  </ServiceTitleBlock>
                  <ServicePrice service={currentService} />
                  <ContactButtonsBlock>
                    {!!currentService.executor?.phone_number && (
                      <ContactPhoneBlock>
                        <ContactPhoneBtnMobile
                          onClick={() => {
                            setShowContactPhone(true);
                          }}
                          href={'tel:' + currentService.executor.phone_number}
                        >
                          Позвонить
                        </ContactPhoneBtnMobile>
                        <ContactPhoneBtnPC
                          onClick={() => {
                            setShowContactPhone(true);
                          }}
                        >
                          Показать телефон
                        </ContactPhoneBtnPC>
                        {showContactPhone && (
                          <ContactPhone
                            onClick={() => setShowContactPhone(false)}
                          >
                            <span>
                              {formatPhone(
                                currentService.executor.phone_number
                              )}
                            </span>
                          </ContactPhone>
                        )}
                      </ContactPhoneBlock>
                    )}
                    <ToCreteBtn onClick={handleClick}>
                      Оставить заявку
                    </ToCreteBtn>
                  </ContactButtonsBlock>
                  <DescriptionTitle>Описание услуги</DescriptionTitle>
                  <ServiceDescription
                    dangerouslySetInnerHTML={{
                      __html: replaceTextAreaNewLine(
                        currentService.description
                      ),
                    }}
                  ></ServiceDescription>
                </ContentBlock>
              </ServiceCardBlock>
              <Executor executor={currentService.executor} />
              <SendOrderBlock
                ref={ref}
                currentService={currentService}
                currentOrder={currentOrder}
                visibleServiceFullCard={visibleServiceFullCard}
              />
            </Scrollbars>
          </ServiceWindow>
        </ServiceWindowContainer>
      )}
    </>
  );
};
