import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL, GPB_ACQUIRING } = GW_SERVICES_PREFIXES;

export const GetAccrualsInfo = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/accruals/`, params);

export const GetNotices = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/notices/`, params);

export const GetBillingInfo = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/acquiring/tenant_accrual_info/`, params);

export const GetPaySettings = () =>
  API.get(`/${INTERNAL}/api/v4/acquiring/pay_settings/`);

export interface ITransaction {
  back_url_f?: string;
  back_url_s?: string;
  accrual?: string;
  sector: string;
  auto_pay: boolean;
  save_card: boolean;
  value: string;
}

export interface IGPBTransaction {
  back_url_f?: string;
  back_url_s?: string;
  amount: number;
  user_id: string;
  save_card?: boolean;
  provider_bank_number: string;
  period: string | null;
  card_id?: string;
  description: string;
  autopay: boolean;
  sector: string;
}

export interface IGPBRequestTransaction {
  back_url_f?: string;
  back_url_s?: string;
  amount: number;
  user_id: string;
  save_card?: boolean;
  provider_bank_number: string;
  request_number: number | null;
  card_id?: string;
  description: string;
}

export interface INotConfirmedTransactions {
  user_id: string;
  date_from: string;
  date_till: string;
}

export const SendTransaction = (data: ITransaction) =>
  API.post(`/${INTERNAL}/api/v4/acquiring/transaction/`, data);

export interface IFastTransaction {
  sector: string;
  card: string;
  amount: number;
  month: string;
}
export const SendFastTransaction = (data: IFastTransaction) =>
  API.post(`/${INTERNAL}/api/v4/acquiring/fast_transaction/`, data);

export const RemoveCard = (cardId: string) =>
  API.delete(`/${INTERNAL}/api/v4/acquiring/pay_settings/${cardId}/`);

export const GetPaymentInfoByNumber = (params: {
  number: string;
  month?: string;
  auto_pay?: boolean;
}) => API.get(`/${INTERNAL}/api/v4/public/pay_by_number/`, params);

export const GetReceipt = ({
  accrual,
  sector_code,
}: {
  accrual: string;
  sector_code: string;
}) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/receipt/`, {
    accrual,
    sector_code,
  });

export const CheckReceiptStatus = ({
  task_id,
  sector_code,
}: {
  task_id: string;
  sector_code: string;
}) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/receipt/`, {
    task_id,
    sector_code,
  });

export const UpdateAutoPay = (
  user_id: string,
  card_id: string,
  data: any
) =>
  API.patch(`/${GPB_ACQUIRING}/users/${user_id}/cards/${card_id}`, data);

export const SendNewTransaction = (data: IGPBTransaction) =>
  API.post(`/${GPB_ACQUIRING}/orders/pay`, data);

export const SendNewRequestTransaction = (
  data: IGPBRequestTransaction
) => API.post(`/${GPB_ACQUIRING}/orders/pay_request`, data);

export const RegisterCard = (data: any) =>
  API.post(`/${GPB_ACQUIRING}/orders/register_card`, data);

export const GetGPBFee = (data: { amount: string }) =>
  API.get(`/${GPB_ACQUIRING}/fees/calculate`, data);

export const RemoveGPBCard = (
  user_id: string,
  card_id: string
) =>
  API.delete(`/${GPB_ACQUIRING}/users/${user_id}/cards/${card_id}`);

export const GetNotConfirmedTransactions = (
  data: INotConfirmedTransactions
) =>
  API.get(`/${GPB_ACQUIRING}/transactions/get_not_confirm`, data);

//TODO: отправка логов
export const SendPaymentRequestLog = (data: any) =>
  API.post(`/${GPB_ACQUIRING}/add_log`, data);
