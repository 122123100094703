import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export interface IInitCall {
  guest_number: string;
}

export const InitCall = (params: IInitCall) =>
  API.post(`/${INTERNAL}/api/v4/public_qr/dialing/`, params);

export interface ISendCode {
  verification_num: string;
}

export const SendCode = (id: string, params: ISendCode) =>
  API.patch(`/${INTERNAL}/api/v4/public_qr/dialing/${id}/`, params);

export interface IGetAddresses {
  phone: string;
  email?: string;
}

export const GetAddresses = (params: IGetAddresses) =>
  API.get(`/${INTERNAL}/api/v4/public_qr/get_account/`, params);

export interface IGetPaymentInfo {
  tenant_id: string;
  sector: string;
  tenant_addresses: any;
}

export const GetPaymentInfo = (params: IGetPaymentInfo) =>
  API.post(`/${INTERNAL}/api/v4/public_qr/account_actions/`, params);

export interface IGetRemainingAddresses {
  pay_action_id: string;
}

export const GetRemainingAddresses = (params: IGetRemainingAddresses) =>
  API.get(`/${INTERNAL}/api/v4/public_qr/account_actions/`, params);

export interface IGetRemainingPaymentInfo {
  tenant_id: string;
  sector: string;
}

export const GetRemainingPaymentInfo = (
  id: string,
  params: IGetRemainingPaymentInfo
) => API.patch(`/${INTERNAL}/api/v4/public_qr/account_actions/${id}/`, params);
