import { Route } from 'react-router-dom';
import { Auth } from './pages/auth';
import { Accruals } from './pages/accruals';
import { ICRUDPermissions } from './pages/auth/types';
import { Main } from './pages/main';
import { Meters } from './pages/meters';
import { News } from './pages/news';
import { Payments } from './pages/payments';
import { Profile } from './pages/profile';
import { Requests } from './pages/requests';
import { Tickets } from './pages/tickets';
import { Catalog } from './pages/catalog';
import { Notifications } from './pages/notifications';
import { QrPayment } from './pages/qr-payment';
import {
  ChangeEmailErrorPage,
  ActivationPageOverlap,
} from './pages/auth/tabs';

/** Роуты неавторизованного приложения **/
export const unauthorizedRoutes = {
  auth: {
    Component: Auth,
    link: '/auth/',
    slug: 'without slug',
    // main: {
    //   link: '/auth/main/', //ПОКА НЕ ИСПОЛЬЗУЕТСЯ
    // },
    services: {
      list: {
        link: '/auth/services/list/',
      },
      tgbot: {
        link: '/auth/services/tgbot/',
      },
      qrcode: {
        link: '/auth/services/qrcode/',
      },
      robot: {
        link: '/auth/services/robot/',
      },
      autopay: {
        link: '/auth/services/autopay/',
      },
      online_payment: {
        link: '/auth/services/online_payment/',
      },
      receipts: {
        link: '/auth/services/receipts/',
      },
      meters: {
        link: '/auth/services/meters/',
      },
    },
    // about: {
    //   link: '/auth/about/', //ПОКА НЕ ИСПОЛЬЗУЕТСЯ
    // },
    // faq: {
    //   link: '/auth/faq/', //ПОКА НЕ ИСПОЛЬЗУЕТСЯ
    // },
    login: {
      link: '/auth/login/',
    },
    registration: {
      link: '/auth/registration/',
    },
    repair: {
      link: '/auth/repair/',
    },
    repairSuccess: {
      link: '/auth/repair_success',
    },
    repairError: {
      link: '/auth/repair_error',
    },
    activation: {
      link: '/auth/activation/:userId/:profileId',
    },
    addProfileSecondStep: {
      link: '/auth/add_tenant/:userId/:profileId/:activationCode',
    },
    activationError: {
      link: '/auth/activation_error',
    },
    redirect: {
      // link: '/auth/main/',
      link: '/auth/login/',
    },
  },
  fromBank: {
    Component: Auth,
    link: '/main/',
    slug: 'without slug',
    from: {
      link: '/main/from/',
    },
  },
  catalog: {
    Component: Catalog,
    link: '/catalog/',
    slug: 'compendium',
    previewMobile: {
      link: '/catalog/preview/mobile/:srv',
    },
    previewPC: {
      link: '/catalog/preview/pc/:srv',
    },
  },
  notifications: {
    Component: Notifications,
    link: '/notifications/',
    slug: 'all',
    settings: {
      link: '/notifications/settings/',
    },
    unsubscribe: {
      link: '/notifications/unsubscribe/',
    },
  },
};

/** Роуты не до конца авторизованного приложения (под персоной) - ПОКА НЕ ИСПОЛЬЗУЕТСЯ **/
export const personAuthorizedRoutes = {
  main: {
    Component: Main,
    link: '/main/',
    slug: 'without slug',
    dashboard: {
      link: '/main/dashboard/',
    },
    from: {
      link: '/main/from/',
    },
    redirect: {
      link: '/main/dashboard/',
    },
  },
};

/** Роуты интерфейса оплаты по QR коду с мобильного **/
/** Работает только на мобильном разрешении (ТАК СДЕЛАНО СПЕЦИАЛЬНО) **/
export const qrRedirectRoutes = {
  qr: {
    Component: QrPayment,
    link: '/qr/',
    slug: 'without slug',
    payment: {
      link: '/qr/payment/',
      phone: {
        link: '/qr/payment/phone/', //Стартовая страничка интерфейса - ввод номера телефона
      },
      confirm: {
        link: '/qr/payment/confirm/', //Страничка ввода кода подтверждения
      },
      address: {
        link: '/qr/payment/address/', //Страничка выбора адреса
      },
      sector: {
        link: '/qr/payment/sector/', //Страничка выбора направления
      },
      register: {
        link: '/qr/payment/register/', //Страничка редиректа на сам ЛКЖ
      },
      email: {
        link: '/qr/payment/email/', //Страничка ввода мейла
      },
      finish: {
        link: '/qr/payment/finish/', //Страничка перед редиректом на эквайер ("Внести аванс" или "Перейти к оплате")
      },
      greetings: {
        link: '/qr/payment/greetings/', //Страничка после редиректа с эквайера, когда оплачено всё
      },
      blocked: {
        link: '/qr/payment/blocked/', //Страничка блокировки оплаты
      },
      continuation: {
        link: '/qr/payment/continuation/', //Страничка после редиректа с эквайера, когда оплачено НЕ всё
      },
    },
  },
};

/** Роуты авторизованного приложения **/
export const authorizedRoutes = {
  updateEmail: {
    Component: ActivationPageOverlap,
    link: '/auth/change_email/:userId/:profileId',
    slug: 'without slug',
  },
  addProfileSecondStep: {
    Component: ActivationPageOverlap,
    link: '/auth/add_tenant/:userId/:profileId/:activationCode',
    slug: 'without slug',
  },
  changeEmailError: {
    Component: ChangeEmailErrorPage,
    link: '/auth/change_email_error',
    slug: 'without slug',
  },
  accruals: {
    Component: Accruals,
    link: '/accruals/',
    slug: 'all_apartments_accruals',
    all: {
      link: '/accruals/all/',
    },
    payment: {
      link: '/accruals/payment/',
    },
    redirect: {
      link: '/accruals/all/',
    },
  },
  payments: {
    Component: Payments,
    link: '/payments/',
    slug: 'all_apartments_accruals',
    list: {
      link: '/payments/list/',
    },
    redirect: {
      link: '/payments/list/',
    },
  },
  main: {
    Component: Main,
    link: '/main/',
    slug: 'without slug',
    dashboard: {
      link: '/main/dashboard/',
    },
    from: {
      link: '/main/from/',
    },
    redirect: {
      link: '/main/dashboard/',
    },
  },
  meters: {
    Component: Meters,
    link: '/meters/',
    slug: 'apartment_meters_data',
    list: {
      link: '/meters/list/',
    },
    redirect: {
      link: '/meters/list/',
    },
  },
  news: {
    Component: News,
    link: '/news/',
    slug: 'news',
    list: {
      link: '/news/list/',
    },
    detail: {
      link: '/news/detail/:id/',
    },
    redirect: {
      link: '/news/list/',
    },
  },
  profile: {
    Component: Profile,
    link: '/profile/',
    slug: 'without slug',
    user: {
      link: '/profile/user/',
    },
    autopay: {
      link: '/profile/autopay/',
      connect: {
        link: '/profile/autopay/connect/',
      },
      disconnect: {
        link: '/profile/autopay/disconnect/',
      },
    },
    services: {
      link: '/profile/services/',
    },
    settings: {
      link: '/profile/settings/',
    },
    insurance: {
      link: '/profile/insurance/',
      osago: {
        link: '/profile/insurance/osago',
      },
      mortgage: {
        link: '/profile/insurance/mortgage',
      },
    },
    info: {
      link: '/profile/info/',
    },
    redirect: {
      link: '/profile/user/',
    },
  },
  requests: {
    Component: Requests,
    link: '/requests/',
    slug: 'request_log',
    // list: {
    //   link: '/requests/list/',
    // },
    create: {
      link: '/requests/create/',
    },
    detail: {
      link: '/requests/detail/',
    },
    redirect: {
      link: '/requests/',
    },
    templateRequest: {
      link: '/requests/template_request/',
      forHistory: '/requests/template_request/',
      slug: 'catalogue_cabinet_positions',
    },
    templateRequestShop: {
      link: '/requests/template_request/shop/:idCategory',
      forHistory: '/requests/template_request/shop/',
    },
    templateRequestExampleOfDocuments: {
      link: '/requests/template_request/images/:idCat/:idImg',
      forHistory: '/requests/template_request/images/',
    },
    templateRequestCategory: {
      link: '/requests/template_request/category/:idCategory',
      forHistory: '/requests/template_request/category/',
    },
    templateRequestPayment: {
      link: '/requests/template_request/payment/:idCategory',
      forHistory: '/requests/template_request/payment/',
    },
    // }
  },
  tickets: {
    Component: Tickets,
    link: '/tickets/',
    slug: 'tickets_from_tenants',
    list: {
      link: '/tickets/list/',
    },
    create: {
      link: '/tickets/create/',
    },
    detail: {
      link: '/tickets/detail/',
    },
    redirect: {
      link: '/tickets/list/',
    },
  },
  catalog: {
    Component: Catalog,
    link: '/catalog/',
    slug: 'compendium',
    // testListPreviewMobile: {
    //   link: '/catalog/test/',
    //   forHistory: '/catalog/test/',
    // },
    servisesList: {
      link: '/catalog/servises_list/',
      forHistory: '/catalog/servises_list/',
    },
  },
};

export const getRoutes = (routes: any, permissions?: ICRUDPermissions) => {
  return (
    permissions
      ? Object.keys(routes).filter(
          (key) =>
            routes[key].slug === 'without slug' ||
            permissions[routes[key].slug]?.includes('R')
        )
      : Object.keys(routes)
  ).map((key) => (
    <Route
      key={routes[key].link}
      path={routes[key].link}
      component={routes[key].Component}
    />
  ));
};
