import { API } from './api';
import { GW_SERVICES_PREFIXES } from '../constants/gateway';

const { INTERNAL } = GW_SERVICES_PREFIXES;

export const GetTicketsList = (params: any) =>
  API.get(`/${INTERNAL}/api/v4/cabinet/tickets/`, params);

export const CreateTicket = (data: any) =>
  API.post(`/${INTERNAL}/api/v4/cabinet/tickets/`, data);

export const UpdateTicketFiles = (id: string, params: FormData) =>
  API.patchFiles(`/${INTERNAL}/api/v4/cabinet/tickets/files/${id}/`, params);
