import React, { useState } from 'react';
import { Confirm, IConfirmSettings } from '../components';

let promise: {
  resolve: (value: string) => void;
  reject: (reason?: string) => void;
} | null = null;

const defaultConfirmSettings: IConfirmSettings = {
  title: 'Подтверждение',
  body: '',
  type: 'confirm',
  confirmText: 'Да',
  dismissText: 'Нет',
};

type ConfirmProps = Partial<IConfirmSettings> & Pick<IConfirmSettings, 'body'>;

export const useConfirm = () => {
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState<IConfirmSettings>({
    ...defaultConfirmSettings,
  });

  const handleShow = (confirmSettings: ConfirmProps) => {
    setSettings({
      ...defaultConfirmSettings,
      ...confirmSettings,
    });
    setShow(true);

    return new Promise((resolve, reject) => {
      promise = {
        resolve,
        reject,
      };
    });
  };

  const handleConfirm = () => {
    promise?.resolve('');
    setShow(false);
  };

  const handleDismiss = () => {
    promise?.reject();
    setShow(false);
  };

  const Component = () => (
    <>
      {show && (
        <Confirm
          {...settings}
          onConfirm={handleConfirm}
          onDismiss={handleDismiss}
        />
      )}
    </>
  );

  return [Component, handleShow] as [() => JSX.Element, typeof handleShow];
};
